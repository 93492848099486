body {
	overscroll-behavior-y: contain;
}

.sidebar {
	position: absolute;
	top: 90px;
	bottom: 0;
	left: 0;
	width: 200px;
	min-height: calc(100% - 90px) !important;
	z-index: 100;
	padding: 23px 0 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#sidebar-wrapper {
	min-height: 100vh !important;
	width: 100vw;
	margin-left: -1rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
	padding: 0.875rem 1.25rem;
	font-size: 1.2rem;
}

#page-content-wrapper {
	min-width: 0;
	width: 100%;
}

.sidebar-item {
	text-decoration: none;
	color:#76b8f4;
	font-weight: bold;
}
.nav-link
{
	border-radius:8px;
}
.nav-link:hover {
	background: #dcdcdc;
	border-radius:8px;

	.sidebar-item {
		color: rgb(68, 60, 60);
	}
}

.exam-number {
	margin-left: 20px;
}

#button-search {
	margin-top: 32px;
	margin-left: 20px;
}

.navbar {
	height: 90px;
	border-bottom: 1px solid #0195d5;
	background-color: #0195d5 !important;
	color: #ffffff;
}

.navbar-logout {
	margin: 0 20px;
	color: #ffffff;
	text-decoration: none;
}

.navbar-logout:hover {
	color: #B22222;
}

.me-auto .navbar-nav {
	text-align: right;
}

.navbar-expand .navbar-nav {
	display: flex;
	flex-direction: column;
}

#welcome-name, #welcome-email {
	display: block;
	align-items: flex-end;
	font-size: 20px;
}

#final-date {
	margin-left: 10px;
}

.back-button-container {
	display: none;
}

@media screen and (max-width: 1023px) {
	#welcome-name, #welcome-email {
		display: block;
		align-items: flex-end;
		font-size: 12px;
	}

	.sidebar {
		position: absolute;
		top: 90px;
		bottom: 0;
		left: 0;
		width: 0px;
		min-height: 100vh !important;
		z-index: 100;
		padding: 48px 0 0;
		// box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
	}
	.sidebar-item {
		text-decoration: none;
		display: none;
	}
	
	// .clinica-logo{
	// 	width: 100%;
	// }

	#talk-with-us {
		display: none;
	}

	.search-items {
		flex-direction: column;
	}

	.exam-number {
		margin-left: 0px;
	}

	#button-search {
		margin-top: 10px;
		margin-left: 0px;
	}

	#final-date {
		margin-left: 0px;
	}
}
