@import "bootstrap/dist/css/bootstrap.min.css";

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background-color: #aaa;
}

::-webkit-scrollbar-thumb {
	background-color: #0195d5;
	border-radius: 8px;
}

body {
	font-family: "Open Sans", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue";
}

.login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	// background: #d9edff;
	background: white;
}
.login-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;
	max-width: 1000px;
	background-color: white;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
	overflow: hidden;
	margin: 0 auto;
	border-radius: 12px;
}

#forget-password {
	flex: 1 0 100%;
	max-width: 480px;
	width: 100%;
	padding: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	#user,
	#password {
		margin-bottom: 10px;
	}

	#change {
		text-align: center;
	}

	#button-change {
		margin-top: 10px;
		padding: 5px 80px;
	}
}
#forget-password p {
	margin-bottom: 30px;
}
#forget-password p.form-title {
	color: #333333;
	font-family: "Josefin Sans", sans-serif;
	font-size: 42px;
	font-weight: bold;
	line-height: 1;
	margin-bottom: 0;
}
#forget-password .ant-form-item-label > label.ant-form-item-required::before {
	display: none;
}
.ant-form-item-control-input-content {
	text-align: left;
}
#forget-password .ant-input-affix-wrapper {
	padding: 12px 15px;
}
#forget-password_username {
	height: 48px;
}
#forget-password .ant-btn {
	height: 42px;
	letter-spacing: 1px;
	border-radius: 6px;
}
.illustration-wrapper {
	display: flex;
	align-items: flex-end;
	max-width: 800px;
	min-height: 100%;
	background-color: #fffdf2;
}
.illustration-wrapper img {
	display: block;
	//width: 100%;
}
#arrow-back {
	margin-left: 15%;
}
@media screen and (max-width: 1023px) {
	.login-box {
		flex-direction: column;
		box-shadow: none;
	}
	.illustration-wrapper {
		max-width: 100%;
		min-height: auto;
	}
	#forget-password {
		max-width: 100%;
	}
	#arrow-back {
		margin-left: 10%;
	}

	#button-change {
		margin-top: 10px;
		padding: 5px 80px;
	}
}

@media screen and (max-width: 400px) {
	#arrow-back {
		margin-left: 5%;
		padding: 0%;
	}
	#arrow-logo {
		margin-left: 0%;
		padding: 0%;
	}
}
