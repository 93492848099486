@import "bootstrap/dist/css/bootstrap.min.css";

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #0195d5;
  border-radius: 8px;
}

body {
  font-family: "Open Sans", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.details-page {
  padding: 20px;
  min-height: calc(100% - 90px);
  position: absolute;
  left: 200px;
  width: calc(100% - 200px);

  background: #f8f8fb;
}
#exam-list {
  padding: 20px;

  .exam-detail {
    #exam-data {
      margin-right: 10px;
    }

    #exam-complete {
      margin-right: 10%;

      button {
        font-size: 25px;
      }
    }

    #all-exams {
      margin-top: 20px;
      //border: 1px solid black;
      border-radius: 5px;

      td {
        padding: 10px;
      }
    }
  }
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
td {
  vertical-align: middle;
}

@media screen and (max-width: 1023px) {
  .details-page {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 20px;
    min-height: calc(100% - 90px);
    position: absolute;
    left: 0px;
    width: 100%;
    background: #f8f8fb;
  }
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #exam-list {
    max-width: 100%;
  }
  #exam-complete {
    text-align: center;
    margin-left: 30px;
  }
  .exam-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media screen and (min-width: 1023px) {
  .exam-detail {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 767px) {
  .show-mobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .above-mobile {
    display: none;
  }
}
