@import "bootstrap/dist/css/bootstrap.min.css";

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #0195d5;
  border-radius: 8px;
}

body {
  font-family: "Open Sans", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.user-page {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 20px;
  min-height: calc(100% - 90px);

  position: absolute;
  left: 200px;
  width: calc(100% - 200px);
  background: #f8f8fb;
}
@media only screen and (min-width: 767px) {
  .show-mobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .above-mobile {
    display: none;
  }
}

#exam-list {
  // padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0;
  border-radius: 0.25rem;

  #all-exams {
    margin-top: 20px;
    border-radius: 5px;

    td {
      padding: 10px;
    }
  }
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
td {
  vertical-align: middle;
}
.pagination {
  justify-content: center;
}

.pagination > li.selected > a {
  background-color: #335ddc;
  color: #ffffff;
}

.badge-exams-primary {
  background-color: #0d6efd !important;
}
.badge-exams-success {
  background-color: #4caf50 !important;
}
@media screen and (max-width: 1023px) {
  .user-page {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 0px;
    min-height: calc(100% - 90px);

    position: absolute;
    left: 0px;
    width: 100%;
    // background: #d9edff;
    background: white;
  }

  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #exam-list {
    max-width: 100%;
    padding: 10px;
  }
}
